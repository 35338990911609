import React from "react"
import PropTypes from "prop-types"
import { Link, graphql } from "gatsby"
import Markdown from "react-commonmark"
import Frame from "../components/Frame"
import SEO from "../components/seo"
import ThermalBackground from "../components/ThermalBackground"
import Cushion from "../components/ui/Cushion"
import MaxWidth from "../components/ui/MaxWidth"
import Overlap from "../components/Overlap"
import Rectangle from "../components/ui/Rectangle"
import Typography from "../components/ui/Typography"
import ConcaveBottom from "../components/ConcaveBottom"

const BlogPostTemplate = ({ data, location }) => {
  const { knowledgeCentreJson: post, contactDetailsJson } = data
  const { title, image, date, body, snippet } = post

  return (
    <React.Fragment>
      <Frame
        currentPathname={location.pathname}
        footerBackgroundColor="vLightGrey"
        contactDetails={contactDetailsJson}
      >
        <SEO title={title} />
        <ThermalBackground component="div">
          <ConcaveBottom fill="vLightGrey">
            <Cushion
              component="div"
              horizontal="small"
              top="xxlarge"
              bottom="xxxxlarge"
              responsive={[
                { minWidth: "palm", props: { horizontal: "medium" } },
                { minWidth: "tab", props: { horizontal: "xlarge" } },
              ]}
            >
              <Typography component="div" align="center">
                <Cushion bottom="large">
                  <Typography component="div" preset="hero" color="white">
                    {title}
                  </Typography>
                </Cushion>
              </Typography>
            </Cushion>
          </ConcaveBottom>
        </ThermalBackground>
        <MaxWidth maxWidth={1000} center>
          <Cushion
            horizontal="small"
            vertical="large"
            responsive={[
              { minWidth: "palm", props: { horizontal: "medium" } },
              { minWidth: "tab", props: { horizontal: "xlarge" } },
            ]}
          >
            <Overlap amount={160}>
              <Cushion bottom="medium">
                <Rectangle radius="rounded">
                  <img src={image} alt={title} style={{ width: "100%" }} />
                </Rectangle>
              </Cushion>
              <Cushion bottom="large">
                <Typography component="div" preset="body">
                  <Cushion bottom="medium">
                    <Typography component="div" fontStyle="italic">
                      {snippet}
                    </Typography>
                  </Cushion>
                  <Markdown source={body} />
                </Typography>
              </Cushion>
            </Overlap>
          </Cushion>
        </MaxWidth>
      </Frame>
    </React.Fragment>
  )
}

BlogPostTemplate.propTypes = {
  data: PropTypes.shape({}).isRequired,
  location: PropTypes.shape({}).isRequired,
  pageContext: PropTypes.shape({}).isRequired,
}
export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    contactDetailsJson {
      email
      mobile
      landline
    }
    knowledgeCentreJson(fields: { slug: { eq: $slug } }) {
      body
      title
      id
      snippet
      date(formatString: "MMMM DD, YYYY")
      image
      fields {
        slug
      }
    }
  }
`
