import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const Overlap = ({ amount, children, style: customStyle }) => {
  return <div style={customStyle}>{children}</div>
}

Overlap.defaultProps = {
  style: {},
}
Overlap.propTypes = {
  amount: PropTypes.number.isRequired,
  children: PropTypes.number.isRequired,
  style: PropTypes.shape({}),
}

const StyledOverlap = styled("div")`
  ${props => ({
    position: "relative",
    zIndex: 4,
    top: `-${props.amount}px`,
    marginBottom: `-${props.amount}px`,
  })}
`

export default StyledOverlap
